<template>
  <div>
    <div class="cta-yellow">
      <div class="cta-yellow__content">
        <h2 class="header-2">
          Y tú, <br />
          ¿ya lo tienes todo listo para la llegada de tu bebé?
        </h2>
        <a
          @click.prevent="$router.push({ name: 'owner-list' })"
          v-show="isAuthenticated"
          style="cursor: pointer"
          class="button button--accent button--lg"
          >Empieza tu lista ahora</a
        >
        <router-link
          to="/signup"
          v-show="!isAuthenticated"
          class="button button--accent button--lg"
          >Empieza tu lista ahora</router-link
        >
      </div>
      <div class="cta-yellow__cover">
        <img loading="lazy" src="../../assets/img/covers/cover-cta-lista.jpg" />
      </div>
    </div>

    <section class="cta-hablan-fixe" v-if="showHablan">
      <h3 class="cta-hablan-fixe__title">Hablan de nosotros</h3>
      <div class="cta-hablan-fixe__logos">
        <div class="cta-hablan-fixe__logo">
          <img
            loading="lazy"
            src="../../assets/img/logos/logo-cta-diari-tarragona.png"
            alt="El diari de Tarragona"
          />
        </div>
        <div class="cta-hablan-fixe__logo">
          <img
            loading="lazy"
            src="../../assets/img/logos/logo-cta-estrella-digital.png"
            alt="Estrella Digital"
          />
        </div>
        <div class="cta-hablan-fixe__logo">
          <img
            loading="lazy"
            src="../../assets/img/logos/logo-cta-elboletin.png"
            alt="El Bloetin"
          />
        </div>
        <div class="cta-hablan-fixe__logo">
          <img
            loading="lazy"
            src="../../assets/img/logos/logo-cta-mundo-financiero.jpg"
            alt="El Mundo financiero"
          />
        </div>
        <div class="cta-hablan-fixe__logo">
          <img
            loading="lazy"
            src="../../assets/img/logos/logo-cta-siglo.png"
            alt="Diario Siglo XXI"
          />
        </div>
        <div class="cta-hablan-fixe__logo">
          <img
            loading="lazy"
            src="../../assets/img/logos/logo-cta-murcia-alpha.png"
            alt="Murcia.com"
          />
        </div>
        <div class="cta-hablan-fixe__logo">
          <img
            loading="lazy"
            src="../../assets/img/logos/logo-cta-expansion-alpha.png"
            alt="Expansion Negocios"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CtaYellow",
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
  },
  props: {
    showHablan: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss"></style>
